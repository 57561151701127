<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>
export default {
  watch: {},
};
</script>
<style>
</style>
